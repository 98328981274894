<template>
  <div class="cart">
    <Header url="cart" @upFile="upFilefun"></Header>
    <div class="catebg">
      <img src="../assets/img/allSpc.jpg" />
    </div>
    <div class="goodslist">
       <div class="item clearfix" v-for="(item,index) in goodslist" :key="index" >
          <div class="goodsimg" @click="showbox(index)">
            <img src="../assets/img/1.png" alt="" >
            <div class="imgtitle line1">西红柿炒鸡蛋</div>
            <img class="qiehuan" src="../assets/img/qiehuan.png" alt="">
          </div>

           <span>标签:</span><input type="text" class="itinput"  value="特价">
           <span>菜名:</span><input type="text"  class="itinput"  value="西红柿炒鸡蛋">
           <span>价格:</span><input type="text" class="itinput"  value="20">
       </div>
      <div class="clearfix"></div>
       <div class="userbox">
              <input type="text" placeholder="请输入联系人电话" v-model="phone"/>
              <input type="text" placeholder="请输入联系人地址" v-model="address"/>
              <input type="button" class="gocart bgcolor" value="立即制作菜谱" @click="submitcart()"/>
       </div>
    </div>

    <div v-if="box" class="otherbox">
      <div class="mark"></div>
      <div class="otherimg">
           <div class="othertitle">点击图片更换 <div class="fr close bgcolor" @click="close()">关闭</div></div>
           <div class="clearfix"> <img class="imglist" @click="changeimg(1)" src="../assets/img/1.png" alt="" v-for="(item,index) in goodslist" :key="index" /></div>
      </div>
    </div>
    <Footer />
    <Msg  v-if="msg.show" :msg="msg"/>
  </div>
</template>
<style>
.catebg{position: relative;}

.cart  .goodslist{width: 1280px;overflow: hidden;margin: auto;}
.cart  .goodslist .item{position: relative;padding: 10px; border-bottom: 1px solid #ccc;line-height: 126px;}
.cart  .goodslist .item .goodsimg{position: relative;width: 126px;  height: 126px; float: left;cursor: pointer;}
.cart  .goodslist .item .qiehuan{position: absolute;top:0;right:0;width: 20px;  height: 20px;  background: #fff;}
.cart  .goodslist .item .imgtitle{position:absolute;bottom: 0;width: 100%;height: 40px;line-height: 40px;background: #000000ab;color: #fff;text-align: center;}
.cart  .goodslist .item span{margin-left: 20px;}
.cart  .goodslist .item .itinput{margin: 10px;padding:6px 10px;width: 150px;border: 1px solid #ccc;outline: 0}

.cart .userbox{position: relative;width: 300px;box-sizing: border-box;}
.cart .userbox input{outline: 0;padding: 10px;display: block;width: 100%;margin: 10px;box-sizing: border-box;border-radius: 4px;border:1px solid #ccc}
.cart .userbox input.gocart{border: 1px solid #1ebcf0;cursor: pointer;color: #fff}
.otherbox{position: fixed;  width: 100%;  height: 100vh;  top: 0;  right: 0;}
.mark{  position: absolute;  background: #000;  width: 100%;  height: 100vh;  z-index: -1;  opacity: 0.5;}
.othertitle{text-align: center;padding-bottom: 20px;font-size: 18px;border-bottom: 1px solid #ccc}
.otherimg{    width: 1200px;position: relative;    margin: 120px auto 0;    height: 300px;    background: #fff;    clear: both;    overflow-y: scroll;
    z-index: 11;    min-height: 240px;    padding: 20px;}
.otherimg .imglist{width: 100px;height: 100px;float: left;margin: 20px;cursor: pointer}
.otherimg .close{    position: absolute;  right: 10px;  top:10px;color: #fff;padding: 10px 20px;cursor: pointer}
</style>
<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import Msg from "@/components/msg";


export default {
  name: 'Cart',
  components:{Msg, Header,Footer},
  data() {
    return {
      id:0,
      goodslist:[1,2,3,4],
      box:false,
      msg:{'show':false},
      address:'',
      phone:''
    }
  },
  mounted(){ },
  methods: {
    submitcart(){
            let that = this
            if(!(/^1[3456789]d{9}$/.test(this.phone))){
                   this.msg.show=true
                   this.msg.msg='手机号错误'
                   this.msg.icon=false
                   return  setTimeout(function(){
                      that.msg.show=false
                   },600)
           }
           if(!this.address){
                this.msg.show=true
                this.msg.msg='请填写地址'
                this.msg.icon=false
               return  setTimeout(function(){
                  that.msg.show=false
               },600)
           }

          this.msg.show=true
          this.msg.msg='菜谱生成中...'
          this.msg.icon=true

    },
    upFilefun(e){
       this.msg.show = e
    },
    showbox(id){
      console.log(id);
       this.box = true;
    },
    changeimg(id){
      console.log(id);
      this.box = false;
    },
    close(){
      this.box = false;
    }
  }
}
</script>
