<template>
  <div class="load">
    <div class="loader" title="2">
      <svg v-if="msg.icon" version="1.1" id="loader-1"  x="0px" y="0px" width="100px" height="100px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
        <path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
          <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite" />
        </path></svg>
      <div class="load-msg">{{msg.msg}}</div>
    </div>
  </div>
 
</template>
<script>
export default {
  name: 'Msg',
  props: {
    msg:{
        type:Object,
        default: () => ({
            flag:false,
            msg:'加载中,请稍后再试',
            icon:true
        })
    }
  }
}
</script>

<style scoped>
.load{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.1);
}
.loader{
  width: 120px;
  text-align: center;
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 70px);
  padding-top: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
#loader-1{
  width: 60px;
  height: 60px;
}
.load-msg{
  height: 50px;
  color: #fff;
  font-size: 14px;
  padding-top: 14px;
}
svg path, svg rect {
  fill: #17a085;
}
</style>